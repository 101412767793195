import { FC } from "react";
import SystemAdminImage from "../../assert/Images/taking-examination-job-application-form-filling-by-female-candidate.jpg";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Button, Image, Row, Col, Typography } from "antd";

const MissionImage = styled(Image)`
  mix-blend-mode: multiply;
  width: 100% !important;
  max-width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "500px" : "500px"};
`;

const Container = styled(Row)`
  padding: 20px;
  flex-direction: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "column-reverse" : "row"};
`;

const ImageContainer = styled(Col)(() => ({
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const TextContainer = styled.div(({ maxWidth, screen }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: ["xs", "sm", "md"].includes(screen) ? "0px" : "20px",
  height: "100%",
  maxWidth: maxWidth || "600px",
  flexDirection: "column",
}));

const TextStyled = styled(Typography)(({ screen }: any) => ({
  padding: "10px 0",
  color: "#63387A",
  fontSize: ["xs", "sm"].includes(screen) ? "12px" : "16px",
  textAlign: "justify",
}));

export const ButtonStyled = styled(Button)(({ screen }: any) => ({
  borderRadius: "10px",
  border: "2px solid #563b7e",
  outline: "none",
  boxShadow: "none",
  backgroundColor: "#563b7e",
  color: "#ffffff",
  padding: "10px 40px",
  height: "auto",
  marginTop: ["sm", "xs", "md"].includes(screen) ? "12px" : "50px",
  ":hover": {
    color: "#563b7e !important",
    borderColor: "#563b7e !important",
    backgroundColor: "#ccc4d8 !important",
    fontWeight: 800,
  },
}));

const Homepage: FC = () => {
  const { screen }: any = useTheme();

  return (
    <>
      <Container screen={screen}>
        <ImageContainer xs={24} lg={10}>
          <TextContainer screen={screen}>
            <TextStyled screen={screen}>
              In the vast tapestry of education, where each student is a unique
              thread, YouCognize stands as the loom that weaves a narrative of
              discovery, potential, and growth. Step into a realm where
              education is not just a path to knowledge but a journey of
              self-discovery, and success is defined by the depth of your
              understanding of your own brilliance.
            </TextStyled>
            <TextStyled screen={screen}>
              At YouCognize, we believe that every student is an undiscovered
              constellation of talents, waiting to be illuminated. Our platform
              is not just an educational hub; it's a sanctuary for those who
              seek to unravel their true potential. Here, we go beyond textbooks
              and exams, diving into the very essence of what makes you
              exceptional.
            </TextStyled>
            <TextStyled screen={screen}>
              Picture education as a blank canvas, and YouCognize as the brush
              that paints a personalized masterpiece of learning. Our commitment
              is to redefine the norms, shatter the limitations, and guide you
              towards a future where your uniqueness is not just celebrated but
              is the driving force behind your success.
            </TextStyled>
            <TextStyled screen={screen}>
              Embark on a journey with us, where curiosity meets guidance, and
              self-discovery becomes the compass that navigates your academic
              voyage. The road ahead is not just about grades; it's about
              understanding the symphony of your strengths and intelligences
              that set you apart.
            </TextStyled>
            <TextStyled screen={screen}>
              Curious minds are the catalysts of innovation, and we are here to
              fuel that curiosity. The YouCognize experience is more than a
              curriculum; it's an invitation to explore the uncharted
              territories of your abilities, an opportunity to redefine what
              success means to you.
            </TextStyled>
            <TextStyled screen={screen}>
              Join us in this quest for knowledge, growth, and self-realization.
              The first step toward your extraordinary future starts here.
            </TextStyled>
          </TextContainer>
        </ImageContainer>
        <ImageContainer xs={24} lg={14}>
          <MissionImage
            screen={screen}
            preview={false}
            src={SystemAdminImage}
          />
        </ImageContainer>
      </Container>
    </>
  );
};

export default Homepage;
