import "./App.css";
import { ThemeProvider, Theme } from "@emotion/react";
import { Grid, Modal, Typography } from "antd";
import { RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { router } from "./App";
import React from "react";
import {
  StudentContextProvider,
  useStudent,
} from "./CustomContext/StudentFormContext";
const { useBreakpoint } = Grid;
const AppRoot: React.FC = () => {
  const screens = useBreakpoint();

  // Function to handle modal visibility

  // useEffect hook to show modal every two minutes

  const screenSize = Object.entries(screens)
    .filter((screen) => screen[1])
    .map((screen) => screen[0]);

  const screen: Theme = { screen: screenSize[screenSize.length - 1] };
  return (
    <React.StrictMode>
      <StudentContextProvider>
        <ThemeProvider theme={screen}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </StudentContextProvider>
    </React.StrictMode>
  );
};
const rootElement = document.getElementById("root")!; // Non-null assertion here

createRoot(rootElement).render(<AppRoot />);
