import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Logo from "../../assert/logo.jpeg";
import { MenuFoldOutlined } from "@ant-design/icons";
import { Drawer, Typography, Image, Row } from "antd";
import { RouteConfig, routerConfig } from "../../routes/routes";
import { Link, useLocation } from "react-router-dom";
const HeaderContainerStyled = styled.div({
  display: "flex",
  height: "100%",
});

const HeaderIconContainerStyled = styled(Row)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const HeaderIconStyled = styled(MenuFoldOutlined)({
  color: "#452f65",
  fontSize: "25px",
});

const TextStyled = styled(Typography)(({ isSelected }: any) => ({
  color: isSelected ? "#664596" : "#9a89b2",
  fontWeight: 800,
  textDecoration: isSelected ? "underline" : "none",
}));

const HeaderNavigation = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  cursor: "pointer",
});

const ImageStyled = styled(Image)(() => ({
  mixBlendMode: "multiply",
}));

const MobileHeader: FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("/");
  const location = useLocation();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);
  return (
    <HeaderContainerStyled>
      <HeaderIconStyled onClick={toggleDrawer} />
      <Drawer
        placement="left"
        onClose={toggleDrawer}
        closeIcon={false}
        open={open}
        width={"70%"}
      >
        <HeaderIconContainerStyled>
          <ImageStyled src={Logo} width={100} height={100} preview={false} />
        </HeaderIconContainerStyled>
        {routerConfig.map((rout: RouteConfig, index: number) => {
          return (
            <div
              key={index}
              style={{ display: rout.path != "*" ? "" : "none" }}
            >
              <HeaderNavigation>
                <Link
                  to={rout.path}
                  style={{ textDecoration: "none" }}
                  onClick={toggleDrawer}
                >
                  <TextStyled isSelected={selectedRoute == rout.path}>
                    {rout.label}
                  </TextStyled>
                </Link>
              </HeaderNavigation>
            </div>
          );
        })}
      </Drawer>
    </HeaderContainerStyled>
  );
};

export default MobileHeader;
