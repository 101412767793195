import { FC } from 'react';
import Logo from '../../assert/logo.jpeg';
import { Button, Image, Typography } from 'antd';
import styled from '@emotion/styled';
import { useTheme } from "@emotion/react";
import DesktopHeader from './desktop';
import MobileHeader from './mobile';

const ContainerStyled = styled.div({
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px',
  height: '100px'
});

const ImageContainerStyled = styled.div(() => ({
  height: '100px',
  width: '100px',
  position: 'relative'
  
}));

const ImageStyled = styled(Image)(() => ({
  mixBlendMode: 'multiply',
  position: 'absolute',
  top: '-50px'
  
}));
const Header: FC = () => {
  const theme: any = useTheme()
  const screen = theme?.screen;
  console.log('ImageContainer', screen);
  return (
    <ContainerStyled>
      <ImageContainerStyled>
      <ImageStyled src={Logo} width={150} preview={false} />
      </ImageContainerStyled>
      {
        ['sm','xs', 'md'].includes(screen) ? <MobileHeader /> : <DesktopHeader />
      }
    </ContainerStyled>
  );
};

export default Header;
