import { Form, Input, Button, Typography, message, Space } from "antd";
import "./index.css";
import styled from "@emotion/styled";
import { database } from "../config/firebase";
import { ref, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
interface StudentFormInterface {
  handleCancel: any;
  showModal: any;
}
const TextStyled = styled(Typography)(({ screen }: any) => ({
  padding: "10px 0",
  color: "#63387A",
  fontSize: ["xs", "sm"].includes(screen) ? "10px" : "14px",
}));
const ButtonStyled = styled(Button)(({ screen }: any) => ({
  borderRadius: "10px",
  border: "2px solid #563b7e",
  outline: "none",
  boxShadow: "none",
  backgroundColor: "#563b7e",
  color: "#ffffff",
  padding: "10px 40px",
  height: "auto",
  marginTop: ["sm", "xs", "md"].includes(screen) ? "12px" : "50px",
  ":hover": {
    color: "#563b7e !important",
    borderColor: "#563b7e !important",
    backgroundColor: "#ccc4d8 !important",
    fontWeight: 800,
  },
}));
const CancelButtonStyled = styled(Button)(({ screen }: any) => ({
  borderRadius: "10px",
  border: "1px solid #563b7e",
  outline: "none",
  boxShadow: "none",
  backgroundColor: "#ffffff",
  color: "#563b7e",
  padding: "10px 40px",
  height: "auto",
  marginRight: "12px",
  marginTop: ["sm", "xs", "md"].includes(screen) ? "12px" : "50px",
  ":hover": {
    color: "#563b7e !important",
    borderColor: "#563b7e !important",
    backgroundColor: "#ccc4d8 !important",
    fontWeight: 800,
  },
}));
const StudentForm = ({ handleCancel, showModal }: StudentFormInterface) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  // Function to handle form submission
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Response recorded successfully",
    });
  };
  const onFinish = async (values: any) => {
    // Handle form submission logic here
    try {
      await set(ref(database, `users/${uuidv4()}`), values);
      success();
      showModal();
      clearAllFeilds();
    } catch (err) {
      console.log("Error", err);
    }
  };
  const clearAllFeilds = () => {
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        requiredMark={true} // Marks all fields as required
      >
        <Form.Item
          label={<TextStyled>Name</TextStyled>}
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<TextStyled>Study Level/Grade</TextStyled>}
          name="studyLevel"
          rules={[
            { required: true, message: "Please enter your study level/grade" },
          ]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<TextStyled>School Name</TextStyled>}
          name="schoolName"
          rules={[{ required: true, message: "Please enter your school name" }]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<TextStyled>City</TextStyled>}
          name="city"
          rules={[{ required: true, message: "Please enter your city" }]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<TextStyled>Contact</TextStyled>}
          name="contact"
          rules={[
            { required: true, message: "Please enter your contact number" },
            {
              pattern: /^\d{10}$/,
              message: "Contact number must be 10 digits",
            },
          ]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<TextStyled>Email</TextStyled>}
          name="email"
          rules={[
            { required: true, message: "Please enter your email address" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
          className="studentFormItem"
        >
          <Input />
        </Form.Item>

        <Form.Item className="studentFormFooter">
          <CancelButtonStyled
            onClick={() => {
              handleCancel();
              clearAllFeilds();
            }}
          >
            Cancel
          </CancelButtonStyled>
          <ButtonStyled htmlType="submit">Submit</ButtonStyled>
        </Form.Item>
      </Form>
    </>
  );
};

export default StudentForm;
