import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

//PUT Credential Details here
const CredDetails = {
  APIKEY: process.env.REACT_APP_APIKEY,
  AUTHDOMAIN: process.env.REACT_APP_AUTHDOMAIN,
  PROJECTID: process.env.REACT_APP_PROJECTID,
  STORAGEBUCKET: process.env.REACT_APP_STORAGEBUCKET,
  MESSAGESENDERID: process.env.REACT_APP_MESSAGESENDERID,
  APPID: process.env.REACT_APP_APPID
}

console.log('CredDetails', CredDetails)

const firebaseConfig = {
  apiKey: CredDetails.APIKEY,
  authDomain: CredDetails.AUTHDOMAIN,
  projectId: CredDetails.PROJECTID,
  storageBucket: CredDetails.STORAGEBUCKET,
  messagingSenderId: CredDetails.MESSAGESENDERID,
  appId: CredDetails.APPID
};

const initialize = initializeApp(firebaseConfig);
export const app = getAuth(initialize);
export const database = getDatabase();