import { FC } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Button, Image } from "antd";
import FirstPageImage from "../../assert/Images/Home.png";
import { useStudent } from "../../CustomContext/StudentFormContext";

export const ButtonStyled = styled(Button)(({ screen }: any) => ({
  borderRadius: "10px",
  border: "2px solid #563b7e",
  outline: "none",
  boxShadow: "none",
  backgroundColor: "#563b7e",
  color: "#ffffff",
  padding: "10px 40px",
  height: "auto",
  margin: "20px",
  ":hover": {
    color: "#563b7e !important",
    borderColor: "#563b7e !important",
    backgroundColor: "#ccc4d8 !important",
    fontWeight: 800,
  },
}));
const Container1 = styled("div")`
  backgroundcolor: #f7f0ff;
  display: flex;
  justify-content: center;
  aligns-item: center;
  flex-direction: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "column" : "column !important"};
  flex-row: row !important;
  min-height: calc(100vh - 100px);
`;

const MainContainer = styled("div")(({ screen }: any) => ({
  display: "flex",
  flexDirection: "column",
  width: "95%",
  margin: "auto",
  minHeight: "calc(100vh - 100px)",
}));
const HeadingDiv = styled("div")`
  font-size: ${(props: { screen: string }) =>
    !["sm", "xs", "md"].includes(props.screen) ? "34px" : "25px"};
  color: #333;
  font-weight: 700;
  padding: 0 20px;
  text-align: center;
`;
const HeadingDiv2 = styled("div")`
  font-size: ${(props: { screen: string }) =>
    !["sm", "xs", "md"].includes(props.screen) ? "18px" : "24px"};

  color: #333;
  padding: 20px;
  font-family: math;
  text-align: center;
`;

const MissionImage = styled(Image)`
  width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "100%" : "80%"};
  max-width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "100%" : "1000px"};
`;

const ImageContainer1 = styled.div(({ screen, height }: any) => ({
  overflow: "hidden",
  display: "flex",
  justifyContent: ["sm", "xs", "md"].includes(screen) ? "center" : "center",
  alignItems: "center",
  gap: "12px",
  width: ["sm", "xs", "md"].includes(screen) ? "100%" : "100%",
  justifyItems: "end",
  height: ["sm", "xs", "md"].includes(screen) ? "auto" : height + "!important",
  margin:"2%",
}));

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "100%" : "100%"};
`;
const Homepage: FC<any> = () => {
  const { screen }: any = useTheme();
  const { toggleModal } = useStudent();
  return (
    <>
      <MainContainer screen={screen}>
        <Container1 screen={screen}>
          <ImageContainer1 screen={screen}>
            <MissionImage
              screen={screen}
              preview={false}
              src={FirstPageImage}
            />
          </ImageContainer1>
          <TextContainer screen={screen}>
            <div
              style={{
                maxWidth: "800px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <HeadingDiv screen={screen}>
                FUEL A PASSION FOR LEARNING: YOUR PATH TO MEANINGFUL GROWTH
                BEGINS HERE
              </HeadingDiv>
              <HeadingDiv2 screen={screen}>
                Unleash Your Unique Potential, Shape Success in Personal Growth,
                Academics, and Beyond - Your Transformative Journey with YC
              </HeadingDiv2>
              <div style={{ textAlign: "center" }}>
                <ButtonStyled
                  screen={screen}
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Start your Journey of Discovery
                </ButtonStyled>
              </div>
            </div>
          </TextContainer>
        </Container1>
      </MainContainer>
    </>
  );
};

export default Homepage;
