import { FC } from "react";
import styled from "@emotion/styled";
import { Button, Image, Row, Col, Typography } from "antd";
import { useTheme } from "@emotion/react";
import Circle1Image from "../../assert/Images/Circle 1 updated.png";
import Circle2Image from "../../assert/Images/Circle 2 updated.png";
import Circle3Image from "../../assert/Images/circle 3 updated.png";

const Container = styled(Row)(({ screen }: any) => ({
  backgroundColor: "#f7f0ff",
  padding: "40px 20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: ["xs", "sm", "md"].includes(screen) ? "100%" : "80%",
  margin: "auto",
}));

const ContainerContent = styled(Row)(() => ({
  maxWidth: "1500px",
  width: "100%",
  padding: "20px 0",
  display: "flex",
  justifyContent: "center",
}));

const TitleStyled = styled(Typography)(({ screen }: any) => ({
  color: "#563b7e",
  fontSize: ["xs", "sm"].includes(screen) ? "20px" : "28px",
  padding: "20px",
  fontWeight: 800,
}));

const TextStyled = styled(Typography)(({ screen }: any) => ({
  color: "#563b7e",
  fontSize: ["xs", "sm"].includes(screen) ? "12px" : "",
  padding: "20px",
  maxWidth: "350px",
  textAlign: "center",
}));

const ContentImageStyled = styled(Image)(() => ({
  mixBlendMode: "multiply",
}));

const ContentImage = styled(Col)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));
const ContentImageContainer = styled("div")(() => ({
  height: "300px",
  width: "300px",
  borderRadius: "100%",
  overflow: "hidden",
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
}));

const SecondSection: FC = () => {
  const { screen }: any = useTheme();

  return (
    <Container  screen={screen}>
      <TitleStyled screen={screen}> BRIDGING THE GAP</TitleStyled>
      <ContainerContent>
        <ContentImage sm={24} md={12} lg={8}>
          <ContentImageContainer>
            <ContentImageStyled src={Circle1Image} preview={false} />
          </ContentImageContainer>
          <TextStyled screen={screen}>
            Weaving connections between a student's untapped potential and the
            vast world of knowledge
          </TextStyled>
        </ContentImage>
        <ContentImage sm={24} md={12} lg={8}>
          <ContentImageContainer>
            <ContentImageStyled src={Circle2Image} preview={false} />
          </ContentImageContainer>
          <TextStyled screen={screen}>
            Connecting your unique talents with tailored educational content
            fostering a journey of self-discovery
          </TextStyled>
        </ContentImage>
        <ContentImage sm={24} md={12} lg={8}>
          <ContentImageContainer>
            <ContentImageStyled src={Circle3Image} preview={false} />
          </ContentImageContainer>
          <TextStyled screen={screen}>
            Nurturing synergy between latent abilities and the expansive
            landscape of available resources bridging growth in life skills
          </TextStyled>
        </ContentImage>
      </ContainerContent>
    </Container>
  );
};

export default SecondSection;
