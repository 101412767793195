import styled from "@emotion/styled";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";
import { ButtonStyled } from "../homepage/firstSection";
import { TextStyled } from "../component/AboutUs/AboutUsMain";
const NotFoundPageDiv = styled<any>("div")`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <NotFoundPageDiv>
      <Result
        status="404"
        title={"404"}
        subTitle={
          <TextStyled>Sorry, the page you visited does not exist.</TextStyled>
        }
        extra={
          <ButtonStyled
            type="primary"
            onClick={() => {
              navigate("/home", {});
            }}
          >
            Back Home
          </ButtonStyled>
        }
      />
    </NotFoundPageDiv>
  );
};

export default NotFoundPage;
