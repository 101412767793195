import { ReactNode, FC } from "react";
import Header from "./header";
import Footer from "./footer";
import { Modal, Typography } from "antd";
import StudentForm from "../commonComponents/StudentForm";
import styled from "@emotion/styled";
import { useStudent } from "../CustomContext/StudentFormContext";

type Props = {
  children: ReactNode;
};
const TextStyled = styled(Typography)(({ screen }: any) => ({
  padding: "10px 0",
  color: "#63387A",
  fontSize: ["xs", "sm"].includes(screen) ? "12px" : "16px",
  fontWeight: "700",
}));
const Layout: FC<Props> = ({ children }) => {
  console.log("layout");
  const { isShow, toggleModal } = useStudent();

  return (
    <>
      <Modal
        title={<TextStyled>Student Form</TextStyled>}
        visible={isShow}
        footer={null}
        onCancel={toggleModal}
      >
        <StudentForm showModal={toggleModal} handleCancel={toggleModal} />
      </Modal>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;