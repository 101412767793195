import { TextContainer, TextStyled } from "../AboutUs/AboutUsMain";

const BlogsMain = () => {
  return (
    <div>
      <TextContainer maxWidth={"80%"}>
        <TextStyled fontWeight={800}>- Educational content</TextStyled>
        <TextStyled fontWeight={800}>- Success stories</TextStyled>
        <TextStyled fontWeight={800}>- Tips for students</TextStyled>
        <TextStyled fontWeight={800}>
          {" "}
          - Updates on education and metalearning
        </TextStyled>
      </TextContainer>
    </div>
  );
};
export default BlogsMain;
