import HomepageLayout from "../layout/index";
import AboutUsMain from "../component/AboutUs/AboutUsMain";
import Homepage from "../homepage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import BlogsMain from "../component/Blogs/BlogsMain";
import CognitionTestMain from "../component/CognitionTest/CognitionTestMain";
export interface RouteConfig {
  path: string;
  element: React.ReactNode;
  label?: string;
}
export const routerConfig: RouteConfig[] = [
  {
    path: "/",
    label: "",
    element: (
      <HomepageLayout>
        <Homepage />
      </HomepageLayout>
    ),
  },
  {
    path: "/home",
    label: "Home",
    element: (
      <HomepageLayout>
         <Homepage />
      </HomepageLayout>
    ),
  },
  {
    path: "/about-us",
    label: "About us",
    element: (
      <HomepageLayout>
        <AboutUsMain />
      </HomepageLayout>
    ),
  },
  // {
  //   path: "/talentAssessment",
  //   label: "Talent Assessment",
  //   element: (
  //     <HomepageLayout>
  //       <TalentAssessmentMain />
  //     </HomepageLayout>
  //   ),
  // },
  {
    path: "/cognitionTest",
    label: "Cognition Test",
    element: (
      <HomepageLayout>
        <CognitionTestMain />
      </HomepageLayout>
    ),
  },
  {
    path: "/blog",
    label: "Blog",
    element: (
      <HomepageLayout>
        <BlogsMain />
      </HomepageLayout>
    ),
  },

  // {
  //   path: "/FAQs",
  //   label: "FAQs",
  //   element: (
  //     <HomepageLayout>
  //       <FirstSection />
  //       <SecondSection />
  //     </HomepageLayout>
  //   ),
  // },
  { path: "*", element: <NotFoundPage /> },
];
