import { FC, useEffect, useState } from "react";
import Logo from "../../assert/logo.jpeg";
import { Button, Image, Row, Typography } from "antd";
import styled from "@emotion/styled";
import { DropdownComp } from "../../component";
import { headerDropdown } from "./dropdown";
import { useTheme } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
import { RouteConfig, routerConfig } from "../../routes/routes";
import { useStudent } from "../../CustomContext/StudentFormContext";

const HeaderContainerStyled = styled.div({
  display: "flex",
  height: "100%",
  alignItems: "center",
});

const ButtonStyled = styled(Button)(() => ({
  borderRadius: "10px",
  border: "none",
  outline: "none",
  boxShadow: "none",
  backgroundColor: "#563b7e",
}));

const TextStyled = styled(Typography)(({ isSelected }: any) => ({
  color: isSelected ? "#664596" : "#9a89b2",
  fontWeight: 800,
  textDecoration: isSelected ? "underline" : "none",
}));

const HeaderNavigation = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 20px",
  cursor: "pointer",
});

const Header: FC = () => {
  const theme: any = useTheme();
  const location = useLocation();
  const { toggleModal } = useStudent();
  const [selectedRoute, setSelectedRoute] = useState<string>("/");
  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);
  return (
    <HeaderContainerStyled>
      {routerConfig.map((rout: RouteConfig, index: number) => {
        console.log("rout", rout.path);
        return (
          <div key={index} style={{ display: rout.path != "*" ? "" : "none" }}>
            <HeaderNavigation>
              <Link to={rout.path} style={{ textDecoration: "none" }}>
                <TextStyled
                  isSelected={selectedRoute == rout.path}
                >
                  {rout.label}
                </TextStyled>
              </Link>
            </HeaderNavigation>
          </div>
        );
      })}
      {/* <HeaderNavigation>
        <DropdownComp items={headerDropdown} />
      </HeaderNavigation> */}
      <HeaderNavigation>
        <ButtonStyled type="primary" onClick={toggleModal}>Get Started</ButtonStyled>
      </HeaderNavigation>
    </HeaderContainerStyled>
  );
};

export default Header;
