import { FC } from "react";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import ThirdSection from "./thirdSection";

const Homepage: FC = () => {
  return (
    <>
      <ThirdSection />
      <SecondSection />
      <FirstSection />
    </>
  );
};

export default Homepage;
