import React, { useContext, createContext, useState } from "react";
const StudentContext = createContext<any>({
  isShow: false,
  toggleModal: () => {},
});
export const StudentContextProvider = ({ children }: any) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const toggleModal = () => {
    setIsShow(!isShow);
  };
  return (
    <StudentContext.Provider value={{ isShow, toggleModal }}>
      {children}
    </StudentContext.Provider>
  );
};
export const useStudent = () => {
  const context = useContext(StudentContext);
  if (!context) {
    throw new Error("useStudent must be used within an AuthProvider");
  }
  return context;
};
