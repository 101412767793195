import styled from "@emotion/styled";
import { Image, Typography } from "antd";
import BackGroundImage from "../../assert/logo.jpeg";
import { useTheme } from "@emotion/react";
import CognitionTest from "../../assert/Images/D.png"; //
import CognitionTest2 from "../../assert/Images/cognition-test2.jpg"; //
import {
  Container,
  ContentDiv,
  ImageContainer,
  MissionImage,
  TextHeaderStyled,
} from "../AboutUs/AboutUsMain";
import { useStudent } from "../../CustomContext/StudentFormContext";
export const TextStyled = styled<any>(Typography)`
  padding: 10px 0;
  color: #63387a;
  font-size: ${({ screen }: any) =>
    ["sm", "xs", "md"].includes(screen) ? "12px" : "16px"};
  font-weight: ${(props: { fontWeight?: number }) => props?.fontWeight};
`;
export const TextDiv = styled<any>("div")`
  padding-left: 16px;
`;
const TextDiv1 = styled<any>("div")`
  color: #63387a;
  font-size: ${({ screen }: any) =>
    ["sm", "xs", "md"].includes(screen) ? "12px" : "16px"};
  font-weight: ${(props: { fontWeight?: number }) => props?.fontWeight};
  font-style: italic;
  margin-top: 24px;
`;
export const TextContainer = styled.div(({ maxWidth }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "20px",
  height: "100%",
  maxWidth: maxWidth || "600px",
  flexDirection: "column",
}));

const MainContainer = styled.div(({ imageUrl, screen }: any) => ({
  // backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)),url(${imageUrl})`,
  width: ["sm", "xs", "md"].includes(screen) ? "100%" : "80%",
  margin: "auto",
  backgroundSize: "cover",
  minHeight: "100vh",
  mixBlendMode: "multiply",
  backgroundPosition: "center",
}));

const CognitionTestMain = () => {
  const theme: any = useTheme();
  const { toggleModal } = useStudent();
  const screen = theme?.screen;
  return (
    <MainContainer imageUrl={BackGroundImage} screen={screen}>
      <TextContainer maxWidth={"100%"}>
        <TextHeaderStyled fontWeight={800} screen={screen}>
          WHAT IS COGNITION TEST ?
        </TextHeaderStyled>
        <Container screen={screen}>
          <ContentDiv screen={screen}>
            <TextStyled fontWeight={800} screen={screen}>
              Uncover the Depths of Your Brilliance
            </TextStyled>
            <TextStyled screen={screen}>
              Welcome to YouCognize, where we believe in the power of
              self-discovery and the limitless potential that lies within each
              individual. As part of our commitment to redefining education, we
              introduce you to our groundbreaking Cognition Test—a personalized
              exploration that goes beyond traditional assessments.
            </TextStyled>
            <TextStyled fontWeight={800} screen={screen}>
              Dermatoglyphics Multiple Intelligence Test (DMIT)
            </TextStyled>
            <TextStyled screen={screen}>
              The Foundation of Our Cognition Test Scientifically known as
              Dermatoglyphics Multiple Intelligence Test (DMIT), our Cognition
              Test builds upon the foundation of this esteemed assessment.
              Dermatoglyphics refers to the study of fingerprints, and the DMIT
              methodology has been recognized for its ability to unveil unique
              cognitive insights. While the name has been personally branded,
              the essence remains rooted in the science of dermatoglyphics and
              the quest to understand individual intelligence.
            </TextStyled>
          </ContentDiv>
          <ImageContainer screen={screen} height="380px">
            {/* <MissionImage screen={screen} preview={false} src={CognitionTest} /> */}
            <Image
              preview={false}
              src={CognitionTest}
              width={350}
              height={"100%"}
            />
          </ImageContainer>
        </Container>
        <TextDiv1>
          Dermatoglyphics, the study of fingerprints and their correlation with
          innate intelligence, dates back over 200 years, with modern DMIT
          techniques providing insights into cognitive abilities and learning
          styles with an accuracy rate of up to 95%
        </TextDiv1>

        <TextHeaderStyled fontWeight={800} screen={screen}>
          How It Works:
        </TextHeaderStyled>
        <TextDiv>
          <TextStyled fontWeight={800} screen={screen}>
            1. Fingerprint Analysis:
          </TextStyled>
          <TextStyled screen={screen}>
            The Cognition Test, stemming from the principles of DMIT, begins
            with a detailed analysis of your fingerprint patterns. These unique
            patterns hold valuable information about your cognitive abilities.
          </TextStyled>

          <TextStyled fontWeight={800} screen={screen}>
            2. Cognitive Preferences:
          </TextStyled>
          <TextStyled screen={screen}>
            The Cognition Test, stemming from the principles of DMIT, begins
            with a detailed analysis of your fingerprint patterns. These unique
            patterns hold valuable information about your cognitive abilities.
          </TextStyled>

          <TextStyled fontWeight={800} screen={screen}>
            3. Learning Styles::
          </TextStyled>
          <TextStyled screen={screen}>
            The Cognition Test, stemming from the principles of DMIT, begins
            with a detailed analysis of your fingerprint patterns. These unique
            patterns hold valuable information about your cognitive abilities.
          </TextStyled>

          <TextStyled fontWeight={800} screen={screen}>
            4. Innate Talents::
          </TextStyled>
          <TextStyled screen={screen}>
            Uncover your innate talents and strengths. The Cognition Test
            reveals aspects of your cognitive profile that can be harnessed to
            excel in various domains, from academics to personal development.
          </TextStyled>
        </TextDiv>
        <TextHeaderStyled
          fontWeight={800}
          screen={screen}
          style={{ marginTop: "52px" }}
        >
          Why Choose the Cognition Test?
        </TextHeaderStyled>
        <Container screen={screen}>
          <ContentDiv screen={screen}>
            <TextStyled screen={screen}>
              Choosing the YouCognize Cognition Test isn't just a decision; it's
              a gateway to unlocking a treasure trove of self-discovery. Here's
              why this test is your key to a future of possibilities:
            </TextStyled>

            <TextStyled fontWeight={800} screen={screen}>
              Personalization Beyond Limits:
            </TextStyled>
            <TextStyled screen={screen}>
              Discover a version of self-awareness that transcends boundaries.
              The Cognition Test doesn't just define your personality; it crafts
              a narrative uniquely yours, revealing facets that extend far
              beyond the ordinary.
            </TextStyled>
            <TextStyled fontWeight={800} screen={screen}>
              Intelligence Unveiled:
            </TextStyled>
            <TextStyled screen={screen}>
              Uncover the layers of your intelligence in ways that defy
              convention. The Cognition Test doesn't just measure intelligence;
              it unveils a tapestry of cognitive prowess, leaving you with a
              profound understanding of the diverse dimensions of your mental
              acumen.
            </TextStyled>
          </ContentDiv>
          <ImageContainer screen={screen}>
            <MissionImage
              screen={screen}
              preview={false}
              src={CognitionTest2}
            />
          </ImageContainer>
        </Container>

        <TextStyled fontWeight={800} screen={screen}>
          Career Insights Aligned with Destiny:
        </TextStyled>
        <TextStyled screen={screen}>
          Embark on a journey to align your career path with destiny's calling.
          The Cognition Test doesn't just suggest careers; it whispers insights
          about your vocational destiny, guiding you towards a professional
          realm where passion meets purpose.
        </TextStyled>

        <TextStyled fontWeight={800} screen={screen}>
          Subjects as Your Personal Symphony:
        </TextStyled>
        <TextStyled screen={screen}>
          Experience education as a symphony crafted just for you. The Cognition
          Test doesn't just recommend subjects; it orchestrates a harmonious
          blend that resonates with your unique learning style, making education
          a melody that you compose.
        </TextStyled>

        <TextStyled fontWeight={800} screen={screen}>
          Personality Unearthed, Relationships Redefined:
        </TextStyled>
        <TextStyled screen={screen}>
          Delve into the depths of your personality, redefining relationships
          along the way. The Cognition Test doesn't just categorize
          personalities; it unravels the intricacies that shape your
          connections, turning relationships into a canvas where every
          brushstroke matters.
        </TextStyled>

        <TextHeaderStyled
          fontWeight={800}
          screen={screen}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={toggleModal}
        >
          Get Started:
        </TextHeaderStyled>
        <TextStyled screen={screen}>
          Embark on a journey of self-discovery with the YouCognize Cognition
          Test—an evolution from the renowned DMIT methodology. Unlock the
          secrets of your cognitive brilliance, and let the personalized
          insights guide you toward a future where success is synonymous with
          your unique strengths.
        </TextStyled>
      </TextContainer>
    </MainContainer>
  );
};
export default CognitionTestMain;
