import { FC } from "react";
import styled from "@emotion/styled";
import { Row, Typography } from "antd";
import {
  InstagramOutlined,
  YoutubeOutlined,
  FacebookOutlined,
} from "@ant-design/icons";

const FooterContainerStyled = styled(Row)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const HeaderIconContainerStyled = styled(Row)({
  backgroundColor: "#563b7e",
  padding: "20px",
});

const HeaderNavigation = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  cursor: "pointer",
});

const TextStyled = styled(Typography)(() => ({
  fontWeight: 800,
  color: "#ffffff",
}));

const FacebookOutlinedStyled = styled(FacebookOutlined)({
  fontSize: "25px",
  margin: "4px",
  color: "#ffffff",
  paddingBottom: "20px",
});

const InstagramIconStyled = styled(InstagramOutlined)({
  color: "#ffffff",
  fontSize: "25px",
  margin: "4px",
  paddingBottom: "20px",
});

const Footer: FC = () => {
  console.log("Footer");
  return (
    <HeaderIconContainerStyled>
      <FooterContainerStyled>
        <HeaderNavigation>
          <TextStyled>About Us</TextStyled>
        </HeaderNavigation>
        <HeaderNavigation>
          <TextStyled>Legal Center</TextStyled>
        </HeaderNavigation>
        <HeaderNavigation>
          <TextStyled>Privacy Policy</TextStyled>
        </HeaderNavigation>
        <HeaderNavigation>
          <TextStyled>Term Services</TextStyled>
        </HeaderNavigation>
        <HeaderNavigation>
          <TextStyled>Help</TextStyled>
        </HeaderNavigation>
      </FooterContainerStyled>
      <FooterContainerStyled>
        <InstagramIconStyled
          onClick={() => {
            window.open(
              "https://www.instagram.com/youcognize?igsh=MTQ4Yjl3b2xzdWpqMw=="
            );
          }}
        />
        <FacebookOutlinedStyled
          onClick={() => {
            window.open(
              "https://www.facebook.com/profile.php?id=61556556273915&mibextid=ZbWKwL"
            );
          }}
        />
      </FooterContainerStyled>
      <FooterContainerStyled>
        <TextStyled>
          Copyright © 2024 YouCognize | Powered by YouCognize
        </TextStyled>
      </FooterContainerStyled>
    </HeaderIconContainerStyled>
  );
};

export default Footer;
