import styled from "@emotion/styled";
import { Button, Image, Row, Col, Typography, Card } from "antd";
import BackGroundImage from "../../assert/logo.jpeg";
import AboutUsImage from "../../assert/Images/A.png";
import Image1 from "../../assert/Images/Holistic.png";
import Image2 from "../../assert/Images/Bridging.png";
import Image3 from "../../assert/Images/Self.png";
import MissionImageUrl from "../../assert/Images/B.png";
import VisionImageUrl from "../../assert/Images/C.jpg";
import FounderImage from "../../assert/Images/founder-image.jpeg";
import { useTheme } from "@emotion/react";
import Meta from "antd/es/card/Meta";
export const TextStyled = styled<any>(Typography)`
  padding: 10px 0;
  color: #63387a;
  font-size: ${({ screen }: any) =>
    ["sm", "xs", "md"].includes(screen) ? "12px" : "16px"};
  font-weight: ${(props: { fontWeight?: number }) => props?.fontWeight};
  text-align: justify;
`;
export const TextHeaderStyled = styled<any>(Typography)`
  padding: 10px 0;
  color: #63387a;
  font-size: ${({ screen }: any) =>
    ["sm", "xs", "md"].includes(screen) ? "16px" : "24px"};
  font-weight: ${(props: { fontWeight?: number }) => props?.fontWeight};
`;

export const TextContainer = styled.div(({ maxWidth, screen }: any) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: ["sm", "xs", "md"].includes(screen) ? "12px" : "20px",
  height: "100%",
  maxWidth: maxWidth || "600px",
  flexDirection: "column",
}));

const MainContainer = styled.div(({ imageUrl, screen }: any) => ({
  // backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)),url(${imageUrl})`,
  width: ["sm", "xs", "md"].includes(screen) ? "100%" : "80%",
  margin: "auto",
  backgroundSize: "cover",
  minHeight: "100vh",
  mixBlendMode: "multiply",
  backgroundPosition: "center",
}));
export const ImageContainer = styled.div(({ screen, height }: any) => ({
  overflow: "hidden",
  display: "flex",
  justifyContent: ["sm", "xs", "md"].includes(screen) ? "center" : "end",
  alignItems: "center",
  gap: "12px",
  width: ["sm", "xs", "md"].includes(screen) ? "auto" : "40%",
  justifyItems: "end",
  height: ["sm", "xs", "md"].includes(screen)
    ? "100% !important"
    : height + "!important",
}));

export const MissionImage = styled<any>(Image)`
  mix-blend-mode: multiply;
  width: 100% !important;
  max-width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen)
      ? "100% !important"
      : "500px !important"};
`;
export const Container = styled("div")`
  display: flex;
  gap: 12px;
  flex-direction: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "column-reverse" : "row"};
  flex-row: row !important;
  margin-top: 24px;
`;
const ContainerTeam = styled("div")`
  display: flex;
  flex-direction: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "column" : "row !important"};
  flex-row: row !important;
`;
export const ContentDiv = styled("div")`
  width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "100%" : "60%"};
  text-align: justify;
`;
const MissionDiv = styled("div")`
  width: ${(props: { screen: string }) =>
    ["sm", "xs", "md"].includes(props.screen) ? "100%" : "100%"};
  justify-content: center;
  margin: auto;
  display: flex;
`;
const TeamContainer = styled("div")(() => ({
  backgroundColor: "#f7f0ff",
  padding: "40px 20px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "72px",
  borderRadius: "8px",
}));

const AboutUsMain = () => {
  const theme: any = useTheme();
  const screen = theme?.screen;
  return (
    <MainContainer imageUrl={BackGroundImage} screen={screen}>
      <TextContainer maxWidth={"100%"} screen={screen}>
        <TextHeaderStyled fontWeight={800} screen={screen}>
          ABOUT US
        </TextHeaderStyled>
        <Container screen={screen}>
          <ContentDiv screen={screen}>
            <TextStyled screen={screen}>
              Welcome to YouCognize, where education transcends boundaries, and
              self-discovery becomes the cornerstone of success. Our journey
              began with a commitment to redefine education, one discovery at a
              time. In a world filled with information overload, we emerge as a
              guiding light, illuminating the path to individual brilliance. 
              YouCognize is a philosophy that celebrates the uniqueness of every
              learner.
            </TextStyled>
            <TextStyled screen={screen}>
              Our platform is a testament to our dedication to nurturing
              holistic growth, bridging the gap between traditional education
              and the dynamic needs of today's learners. With innovation at our
              core, we provide personalized insights and empower students to
              embrace their distinct talents. Our vision is to create a learning
              environment where students not only excel academically but also
              thrive as individuals, discovering their true potential along the
              way. Join us on this transformative journey, where education
              becomes a personalized adventure of self-discovery and
              empowerment.
            </TextStyled>
            {/* <TextStyled fontWeight={600} screen={screen}>
              Our Journey
            </TextStyled>
            <TextStyled screen={screen}>
              At the heart of our story is the belief that education should be a
              transformative experience, one that goes beyond the conventional
              norms and resonates with the individuality of each learner.
              YouCognize was founded with the vision to revolutionize the way we
              approach learning, shifting the focus from standardized education
              to a personalized journey of growth.
            </TextStyled>
            <TextStyled fontWeight={600} screen={screen}>
              Nurturing Individual Brilliance:
            </TextStyled>
            <TextStyled screen={screen}>
              In a world inundated with information, YouCognize emerges as a
              beacon guiding students towards the core of their brilliance. We
              recognize that every individual is a unique constellation of
              talents, waiting to be explored. Our platform is not merely an
              educational tool; it is a philosophy that celebrates the diversity
              of learning and empowers students to embrace their uniqueness.
            </TextStyled> */}
          </ContentDiv>
          <ImageContainer screen={screen} height={"100%"}>
            <MissionImage screen={screen} preview={false} src={AboutUsImage} />
          </ImageContainer>
        </Container>
        {/* 
        <TextStyled fontWeight={600} screen={screen}>
          Our Commitment
        </TextStyled>
        <TextStyled screen={screen}>
          YouCognize is more than an EdTech startup; it's a commitment to
          fostering a holistic educational experience. Our journey began with a
          dedication to bridging the gap between traditional education and the
          multifaceted needs of learners. Through innovation, personalized
          insights, and a profound understanding of cognitive abilities, we
          strive to reshape the narrative of education.
        </TextStyled>
        <TextStyled screen={screen}>
          Our mission at YouCognize is to redefine education, one discovery at a
          time. We strive to create a learning environment where students not
          only excel academically but also discover and enhance their distinct
          talents. Our vision is to bridge the gap between traditional education
          and the ever-evolving needs of learners, fostering a future where each
          student’s journey is uniquely their own.
        </TextStyled> */}
      </TextContainer>

      <TextContainer maxWidth={"100%"} screen={screen}>
        <TextHeaderStyled fontWeight={800} screen={screen}>
          Mission
        </TextHeaderStyled>
        <MissionDiv screen={screen}>
          <MissionImage screen={screen} preview={false} src={Image1} />
          <MissionImage screen={screen} preview={false} src={Image2} />
          <MissionImage screen={screen} preview={false} src={Image3} />
        </MissionDiv>
        <Container screen={screen}>
          <ImageContainer screen={screen} height={"100%"}>
            <Image
              preview={false}
              src={MissionImageUrl}
              width={250}
              height={200}
            />
          </ImageContainer>
          <ContentDiv screen={screen}>
            <TextStyled screen={screen}>
              At YouCognize, we're on a mission to revolutionize education by
              celebrating the unique brilliance within each student. Our
              holistic approach nurtures personal growth, while innovative tools
              bridge gaps in traditional education. We empower students to
              navigate their journey with confidence, fostering a future where
              success is defined by
            </TextStyled>
            {/* <TextStyled screen={screen}>
              YouCognize is on a mission to redefine education, one discovery at
              a time. Our mission is rooted in the firm belief that every
              individual has a unique brilliance waiting to be unveiled. We are
              committed to providing a holistic educational experience that goes
              beyond the traditional confines, nurturing not just academic
              excellence but also personal growth, self-awareness, and a deep
              understanding of one's cognitive abilities.
            </TextStyled>
            <TextStyled screen={screen}>
              Through innovative tools, personalized insights, and a dedication
              to continuous improvement, our mission is to bridge the gap
              between conventional education and the multifaceted needs of
              learners. We strive to empower students to navigate their
              educational journeys with confidence, curiosity, and a sense of
              purpose.
            </TextStyled>
            <TextStyled screen={screen}>
              YouCognize is not just a platform; it's a movement towards a
              future where education becomes a celebration of uniqueness, and
              every learner is equipped with the tools to thrive in a rapidly
              evolving world. Our mission is to guide individuals towards a
              future where success is defined by their ability to embrace their
              distinct talents and contribute meaningfully to the global
              community.
            </TextStyled> */}
          </ContentDiv>
        </Container>

        <TextHeaderStyled fontWeight={800} screen={screen}>
          Vision
        </TextHeaderStyled>
        <Container screen={screen} style={{ marginTop: "24px" }}>
          <ContentDiv screen={screen}>
            <TextStyled screen={screen}>
              We envision a world where education breaks free from its
              traditional confines, empowering individuals to unlock their full
              potential. Our vision is to pioneer a new era of learning, where
              each student embarks on a personalized journey of self-discovery
              and growth. In this future, students actively engage in their
              education, leveraging their unique strengths to contribute to a
              global community of learners. YouCognize serves as a catalyst for
              transformative education, fostering a lifelong love for learning
              that transcends classrooms and tests. Join us in shaping an epoch
              where success is defined by deep self-understanding and thriving
              in all aspects of life.
            </TextStyled>
            {/* <TextStyled screen={screen}>
              In this envisioned future, students are not mere recipients of
              information; they are active participants in their education,
              navigating a landscape tailored to their unique strengths and
              intelligences. Our vision is to create a global community of
              learners who not only excel academically but also embrace their
              individuality, contributing their diverse talents to the broader
              tapestry of human progress.
            </TextStyled>
            <TextStyled screen={screen}>
              We see YouCognize as a catalyst for transformative education,
              fostering a love for learning that extends beyond classrooms and
              standardized tests. Our vision is to guide the next epoch of
              learning, where success is measured not only by academic
              achievements but also by the depth of self-understanding and the
              ability to thrive in all aspects of life.
            </TextStyled> */}
          </ContentDiv>

          <ImageContainer screen={screen} height={"100%"}>
            <Image
              preview={false}
              src={VisionImageUrl}
              width={["sm", "xs", "md"].includes(screen) ? "100%" : 400}
              height={250}
            />
          </ImageContainer>
        </Container>

        <TeamContainer>
          <TextHeaderStyled
            fontWeight={800}
            screen={screen}
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontSize: "34px",
            }}
          >
            Our Founder
          </TextHeaderStyled>
          <ContainerTeam screen={screen}>
            <Card
              hoverable
              style={{ width: ["sm", "xs", "md"].includes(screen) ? " " : 350 }}
              cover={
                <MissionImage
                  screen={screen}
                  preview={false}
                  src={FounderImage}
                />
              }
            >
              <Meta title="Mr. Sameer Asif" description="FOUNDER DIRECTOR" />
            </Card>
            <ContentDiv
              screen={screen}
              style={{
                width: "100%",
                paddingLeft: ["sm", "xs", "md"].includes(screen)
                  ? "0px"
                  : "12px",
              }}
            >
              <TextStyled screen={screen}>
                At the helm of YouCognize stands our founder director, a
                dedicated visionary and the driving force behind our commitment
                to transformative education. With an unwavering passion for
                empowering students, Mr. Sameer has been a trailblazer in the
                field of educational innovation.
              </TextStyled>
              <TextStyled screen={screen}>
                Mr. Sameer’s journey is fueled by a profound belief that
                education should be a catalyst for personal growth. His vision
                is to bridge the gap between conventional learning and the
                untapped potential within each student. Under his leadership,
                YouCognize has become synonymous with unlocking talents,
                nurturing creativity, and fostering a holistic approach to
                learning.
              </TextStyled>
              <TextStyled screen={screen}>
                With years of experience and a keen understanding of the
                evolving educational landscape, Mr. Sameer champions the cause
                of personalized growth. He envisions YouCognize as a platform
                that not only imparts knowledge but also guides students on a
                journey of self-discovery, resilience, and excellence.
              </TextStyled>
            </ContentDiv>
          </ContainerTeam>

          <TextStyled screen={screen}>
            Our founder’s commitment to innovation is reflected in YouCognize’s
            groundbreaking methodologies. From personalized assessments to
            holistic training programs, he spearheads initiatives that redefine
            the way we perceive and experience education. His forward-thinking
            approach is at the heart of YouCognize’s success. Beyond the
            boardroom, Mr. Sameer is dedicated to fostering a community that
            celebrates growth, diversity, and collective achievement. His
            leadership inspires a team that shares his passion for transforming
            education into a meaningful and impactful journey for every student.
          </TextStyled>
        </TeamContainer>
      </TextContainer>
    </MainContainer>
  );
};
export default AboutUsMain;
